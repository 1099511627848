import React from 'react';
import { MdOutlineDateRange } from "react-icons/md";
import logo from '../images/AppIcon.png';


function BlogCard({ title, description, imageUrl, date, id, slug }) {
    return (

        <a href={`/news/${slug}`} className="col-span-1">
                <div className="relative flex flex-col my-6 blog-content shadow-lg  rounded-lg w-96">
                    <div className="relative h-56 m-2.5 overflow-hidden text-white rounded-md news-content">
                    <img src={imageUrl} alt="card-image" />
                    </div>
                    <div className="p-4">
                    <div className="mb-4 rounded-full bg-cyan-600 py-0.5 px-2.5 border border-transparent text-xs text-white transition-all shadow-sm w-20 text-center">
                        Latest
                    </div>
                    <h6 className="mb-2 text-white text-xl font-semibold">
                        {title}
                    </h6>
                    {/*<p className="text-white leading-normal font-light">*/}
                    {/*    {description}*/}
                    {/*</p>*/}
                    </div>
                
                    <div className="flex items-center justify-between p-4">
                    <div className="flex items-center">
                        <img
                        alt="Tania Andrew"
                        src={logo}
                        className="relative inline-block h-8 w-8 rounded-full"
                        />
                        <div className="flex flex-col ml-3 text-sm">
                        <span className="text-white font-semibold">Top 11 Admin</span>
                        <span className="text-slate-200">
                            {date}
                        </span>
                        </div>
                    </div>
                    </div>
                </div> 
            </a>

    );
}

export default BlogCard;