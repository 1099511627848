import React, { useEffect, useState} from 'react';
import bgOverlay from "../images/Background.png";
import {Footer, Header, BlogCard, BlogMain} from "../components";
import {BANNER_API_URL, NEWS_API_URL} from "../Data/api";

const News = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch(
                    "https://admin.top11india.net/api/api.php?key=GetAllNews&website=top11india.net"
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setNews(data);
            } catch (error) {
                console.error("Error fetching news:", error);
            }
        };

        fetchNews();
    }, []);


    return (
        <>
            <div style={{
                backgroundImage: `url(${bgOverlay})`,
                height: '100%',
                width: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <Header/>
                <br/>
                <br/>


                <div className="mt-8">
                   <div className='py-8 px-12'>
                       <div className='grid grid-cols-4 gap-4'>
                           <div className='col-span-4'>
                               <BlogMain/>
                           </div>
                           <div className="col-span-4">
                               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  place-items-center">
                                   {news.map((item, index) => (
                                       <BlogCard
                                           key={index}
                                           date={item.date}
                                           description={item.content}
                                           title={item.title}
                                           id={item.id}
                                           slug={item.slug}
                                           imageUrl={item.n_image}
                                       />
                                   ))}
                               </div>
                           </div>
                       </div>


                   </div>
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default News;