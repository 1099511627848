// serviceData.js
import card_image from '../images/card1.webp';
import icon_one from '../images/card-icon-1-old.webp';
import icon_two from '../images/card-icon-2-old.webp';
import icon_three from '../images/card-icon-3-old.webp';
import icon_four from '../images/card-icon-4-old.webp';

export const services = [
    {
        name: 'Fast and Secure Withdrawals',
        description: '',
        background: card_image,
        icon: icon_one,
    },
    {
        name: 'Fair play & Licensed',
        description: '',
        background: card_image,
        icon: icon_two,
    },
    {
        name: ' Live Streaming and Game Updates',
        description: '',
        background: card_image,
        icon: icon_three,
    },
    {
        name: '24/7 customer support',
        description: '',
        background: card_image,
        icon: icon_four,
    },
];