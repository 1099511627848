import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import bgOverlay from "../images/Background.png";
import {Header} from "../components";
import logo from "../images/AppIcon.png";

const NewsItem = () => {
    const { slug } = useParams();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                console.log("slug : "+slug)
                const response = await fetch(
                    `https://admin.top11india.net/api/api.php?key=GetNewsContent&slug=${slug}`
                );
                console.log(response)
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
                const data = await response.json();

                setNews(data[0]);
                setError(null); // Clear any previous errors
            } catch (error) {
                setError(error.message);
                console.error("Error fetching news:", error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchNews();
    }, [slug]); // Dependency array ensures fetch runs when 'id' changes

    if (loading) {
        return <div>Loading...</div>;
    }

    // Render error state
    if (error) {
        return <div>Error: {error}</div>;
    }
    return (

        <div style={{
            backgroundImage: `url(${bgOverlay})`,
            height: '100%',
            width: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
            <Helmet>
                <title>{"TOP 11 | Blog"}</title>
                <meta name="description" content={news.meta_des || ""}/>
                <meta name="keywords" content={news.keywords || ""}/>
                <meta name="author" content="Emimos"/>
            </Helmet>
            <Header/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto  p-2 ">

                    <div className="py-8 blog-content p-2">
                        <h1 className="text-3xl text-themeYellow font-bold mb-2">{news.title}</h1>
                        <p className="text-white text-sm">Published on <time datetime={news.date}> {news.date}</time>
                        </p>
                    </div>


                    <img src={news.n_image} alt="Featured image"
                         className="w-full h-auto "/>
                    <div className="flex items-center justify-between p-4 bg-themeYellow">
                        <div className="flex items-center">
                            <img
                                alt="Tania Andrew"
                                src={logo}
                                className="relative inline-block h-8 w-8 rounded-full"
                            />
                            <div className="flex flex-col ml-3 text-sm ">
                                <span className="text-black font-semibold ">Top 11 Admin</span>
                                <span className="text-black">
                            {news.date}
                        </span>
                            </div>
                        </div>
                    </div>
                    <div className=" blog-content">
                        <br/>
                        <br/>
                        <div
                            className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto text-white p-4 html-text">
                            <div
                                className="prose"
                                dangerouslySetInnerHTML={{__html: news.content}}
                            />
                        </div>
                    </div>

                </div>
            </div>
            <br/>
            <br/>
        </div>
    );
};

export default NewsItem;